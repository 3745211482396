/* eslint-disable camelcase */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { injectAuthorizationToken, refreshToken, presignedUrlInterceptor } from './initAxios';

const workflowServiceBaseUrl = process.env.NEXT_PUBLIC_WORKFLOW_SERVICE_URL;
const client = axios.create({
  baseURL: workflowServiceBaseUrl
});

client.interceptors.request.use(injectAuthorizationToken);
client.interceptors.response.use(presignedUrlInterceptor);
createAuthRefreshInterceptor(client, refreshToken);

export default client;
