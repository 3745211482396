import * as types from '../accounts.types';
import client from 'utils/workflowClient';
import { getUsers } from './users';

export const createTeam = ({ name, organizationId, contactEmail }) => {
  return async (dispatch) => {
    dispatch({
      type: types.CREATE_TEAM_REQUEST
    });
    try {
      const { data = {} } = await client.post(`/accounts/teams`, {
        name,
        organizationId,
        contactEmail
      });

      const { team = {}, organization = {} } = data;
      return dispatch({
        type: types.CREATE_TEAM_SUCCESS,
        payload: {
          team,
          organization
        }
      });
    } catch (err) {
      return dispatch({
        type: types.CREATE_TEAM_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const editTeam = ({ teamId, name, disabled, members }) => {
  return async (dispatch) => {
    dispatch({
      type: types.EDIT_TEAM_REQUEST
    });

    try {
      const postData = {
        teamId
      };
      if (name) {
        postData['name'] = name;
      }
      if (disabled != undefined) {
        postData['disabled'] = disabled;
      }
      if (members) {
        postData['members'] = members;
      }

      const { data = {} } = await client.post(`/accounts/teams/${teamId}`, postData);

      const { team = {} } = data;

      if (members) {
        dispatch(getUsers({ userIds: members }));
      }
      return dispatch({
        type: types.EDIT_TEAM_SUCCESS,
        payload: {
          team
        }
      });
    } catch (err) {
      return dispatch({
        type: types.EDIT_TEAM_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const listTeams = () => {
  return async (dispatch) => {
    dispatch({
      type: types.LIST_TEAM_REQUEST
    });

    try {
      const { data = {} } = await client.get('/accounts/teams');
      const { list: teams = {} } = data;

      return dispatch({
        type: types.LIST_TEAM_SUCCESS,
        payload: {
          teams
        }
      });
    } catch (err) {
      return dispatch({
        type: types.LIST_TEAM_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const addMembers = ({ organizationId, teamId }) => {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_MEMBERS_REQUEST
    });

    try {
      const { data = {} } = await axios.post(`/accounts/${organizationId}/${teamId}`);

      const { users = [] } = data;

      return dispatch({
        type: types.ADD_MEMBERS_SUCCESS,
        payload: {
          users
        }
      });
    } catch (err) {
      return dispatch({
        type: types.ADD_MEMBERS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
