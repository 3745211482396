import * as types from './accounts.types';

export const initialState = {
  organizations: [],
  teams: [],
  users: [],
  userRoles: [
    { id: 1, name: 'Super Admin' },
    { id: 2, name: 'Admin' },
    { id: 3, name: 'Regular' }
  ],
  error: null,
  loading: false
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_USERS_REQUEST:
    case types.LIST_USERS_REQUEST:
    case types.CREATE_TEAM_REQUEST:
    case types.CREATE_ORGANIZATION_REQUEST:
    case types.EDIT_TEAM_REQUEST:
    case types.EDIT_ORGANIZATION_REQUEST:
    case types.CREATE_USER_REQUEST:
    case types.LIST_TEAM_REQUEST:
    case types.LIST_ORGANIZATION_REQUEST: {
      return {
        ...state,
        error: null
      };
    }
    case types.LIST_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organizations: payload.organizations
      };
    }
    case types.CREATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organizations: [...state.organizations, payload.organization]
      };
    }
    case types.CREATE_TEAM_SUCCESS: {
      return {
        ...state,
        teams: [...state.teams, payload.team]
      };
    }
    case types.LIST_TEAM_SUCCESS: {
      return {
        ...state,
        teams: payload.teams
      };
    }
    case types.CREATE_USER_SUCCESS: {
      return {
        ...state,
        users: [...state.users, payload.user]
      };
    }
    case types.EDIT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        organizations: payload.organizations
      };
    }
    case types.CREATE_TEAM_FAILURE:
    case types.CREATE_ORGANIZATION_FAILURE:
    case types.EDIT_TEAM_FAILURE:
    case types.LIST_TEAM_FAILURE:
    case types.EDIT_ORGANIZATION_FAILURE:
    case types.LIST_ORGANIZATION_FAILURE:
    case types.CREATE_USER_FAILURE:
    case types.GET_USERS_FAILURE:
    case types.LIST_USERS_FAILURE:
    case types.CHANGE_USER_PASSWORD_FAILURE: {
      return {
        ...state,
        error: payload.error
      };
    }
    case types.LIST_USERS_SUCCESS: {
      return {
        ...state,
        users: payload.users
      };
    }
    case types.GET_USERS_SUCCESS: {
      return {
        ...state,
        users: state.users.map((currentUser) => payload.users.find((u) => u.id === currentUser.id) || currentUser)
      };
    }
    case types.EDIT_TEAM_SUCCESS:
      return {
        ...state,
        teams: state.teams.map((team) => (team.id != payload.team.id ? team : payload.team))
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((currentUser) => (currentUser.id == payload.user.id ? payload.user : currentUser))
      };
    case types.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}
