import * as types from '../accounts.types';
import client from 'utils/workflowClient';

export const createUser = ({ name, organizationId, teamId, email, roleId, password }) => {
  return async (dispatch) => {
    dispatch({
      type: types.CREATE_USER_REQUEST
    });

    try {
      const { data = {} } = await client.post(`/signup`, {
        username: email,
        password,
        userDetails: {
          name,
          organizationId,
          teamId,
          email,
          roleId
        }
      });

      const { userDetails = {} } = data;
      return dispatch({
        type: types.CREATE_USER_SUCCESS,
        payload: {
          user: userDetails,
          organization: userDetails.organization
        }
      });
    } catch (err) {
      const details = err.response && err.response.data ? `Details: ${err.response.data.error}` : '';
      const message = `Can't create user. ${details}`;
      return dispatch({
        type: types.CREATE_USER_FAILURE,
        payload: {
          error: message
        }
      });
    }
  };
};

export const listUsers = () => {
  return async (dispatch) => {
    dispatch({
      type: types.LIST_USERS_REQUEST
    });

    try {
      const { data = {} } = await client.get(`/accounts/users`);
      const { list: users = [] } = data;

      return dispatch({
        type: types.LIST_USERS_SUCCESS,
        payload: {
          users: users
        }
      });
    } catch (err) {
      return dispatch({
        type: types.LIST_USERS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const updatePassword = ({ newPassword }) => {
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_PASSWORD_REQUEST
    });

    try {
      const { data = {} } = await client.post(`/accounts/myprofile/update_password`, { newPassword });

      const { password = {} } = data;

      return dispatch({
        type: types.UPDATE_PASSWORD_SUCCESS,
        payload: {
          password
        }
      });
    } catch (err) {
      return dispatch({
        type: types.UPDATE_PASSWORD_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const getUsers =
  ({ userIds }) =>
  async (dispatch) => {
    dispatch({
      type: types.GET_USERS_REQUEST
    });

    try {
      const { data = {} } = await client.get(`/accounts/users?ids=${userIds}`);
      const { list: users = {} } = data;

      return dispatch({
        type: types.GET_USERS_SUCCESS,
        payload: {
          users
        }
      });
    } catch (err) {
      return dispatch({
        type: types.GET_USERS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };

export const updateUser = ({ userId, name, disabled, role, team, settings }) => {
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_USER_REQUEST
    });
    try {
      const payload = {};

      if (name) {
        payload.name = name;
      }

      if (typeof disabled != undefined) {
        payload.disabled = disabled;
      }

      if (role) {
        payload.role = role;
      }

      if (team) {
        payload.team = team;
      }

      if (settings) {
        payload.settings = settings;
      }

      const { data = {} } = await client.post(`/accounts/users/${userId}`, payload);
      const { user = {} } = data;

      return dispatch({
        type: types.UPDATE_USER_SUCCESS,
        payload: {
          user
        }
      });
    } catch (err) {
      return dispatch({
        type: types.UPDATE_USER_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const editUser = ({ userId, organizationId, teamId, name, email, roleId, disabled }) => {
  return async (dispatch) => {
    dispatch({
      type: types.EDIT_USER_REQUEST
    });

    try {
      const { data = {} } = await client.post(`/accounts/users`, {
        userId,
        organizationId,
        teamId,
        name,
        email,
        roleId,
        disabled
      });

      const { user = {} } = data;
      return dispatch({
        type: types.EDIT_USER_SUCCESS,
        payload: {
          user
        }
      });
    } catch (err) {
      return dispatch({
        type: types.EDIT_USER_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const changeUserPassword = ({ userId, newPassword }) => {
  return async (dispatch) => {
    dispatch({
      type: types.CHANGE_USER_PASSWORD_REQUEST
    });

    try {
      const response = await client.post(`/accounts/users/${userId}/set_password`, {
        newPassword
      });

      return dispatch({
        type: types.CHANGE_USER_PASSWORD_SUCCESS
      });
    } catch (err) {
      console.log(err);
      console.log(err.response.data);
      return dispatch({
        type: types.CHANGE_USER_PASSWORD_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
