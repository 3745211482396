/* eslint-disable camelcase */
import * as types from '../accounts.types';
import client from 'utils/workflowClient';

export const createOrganization = ({ name, contactEmail, licenseStart, licenseEnd, createdOn }) => {
  return async (dispatch) => {
    dispatch({
      type: types.CREATE_ORGANIZATION_REQUEST
    });

    try {
      const { data = {} } = await client.post('/accounts/organizations', {
        name,
        contactEmail,
        licenseStart,
        licenseEnd,
        createdOn
      });

      const { organization = {} } = data;
      return dispatch({
        type: types.CREATE_ORGANIZATION_SUCCESS,
        payload: {
          organization
        }
      });
    } catch (err) {
      return dispatch({
        type: types.CREATE_ORGANIZATION_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const listOrganizations = () => {
  return async (dispatch) => {
    dispatch({
      type: types.LIST_ORGANIZATION_REQUEST
    });

    try {
      const { data = {} } = await client.get('/accounts/organizations');

      const { list: organizations = {} } = data;
      return dispatch({
        type: types.LIST_ORGANIZATION_SUCCESS,
        payload: {
          organizations
        }
      });
    } catch (err) {
      return dispatch({
        type: types.LIST_ORGANIZATION_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const editOrganization = ({ organizationId, name, contactEmail, licenseEnd, status }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.EDIT_ORGANIZATION_REQUEST
    });

    const { organizations = [] } = getState().accounts;

    try {
      const { data = {} } = await client.post(`/accounts/organizations/${organizationId}`, {
        organizationId,
        name,
        contactEmail,
        licenseEnd,
        status
      });

      const { organization: updated = {} } = data;
      const updatedOrgs = organizations.map((o) => (o.id === organizationId ? updated : o));

      return dispatch({
        type: types.EDIT_ORGANIZATION_SUCCESS,
        payload: {
          organizations: updatedOrgs
        }
      });
    } catch (err) {
      console.log(err);
      return dispatch({
        type: types.EDIT_ORGANIZATION_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
