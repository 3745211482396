const prefix = '@qes/accounts';

export const CREATE_ORGANIZATION_REQUEST = `${prefix}/CREATE_ORGANIZATION_REQUEST`;
export const CREATE_ORGANIZATION_SUCCESS = `${prefix}/CREATE_ORGANIZATION_SUCCESS`;
export const CREATE_ORGANIZATION_FAILURE = `${prefix}/CREATE_ORGANIZATION_FAILURE`;

export const LIST_ORGANIZATION_REQUEST = `${prefix}/LIST_ORGANIZATION_REQUEST`;
export const LIST_ORGANIZATION_SUCCESS = `${prefix}/LIST_ORGANIZATION_SUCCESS`;
export const LIST_ORGANIZATION_FAILURE = `${prefix}/LIST_ORGANIZATION_FAILURE`;

export const EDIT_ORGANIZATION_REQUEST = `${prefix}/EDIT_ORGANIZATION_REQUEST`;
export const EDIT_ORGANIZATION_SUCCESS = `${prefix}/EDIT_ORGANIZATION_SUCCESS`;
export const EDIT_ORGANIZATION_FAILURE = `${prefix}/EDIT_ORGANIZATION_FAILURE`;

export const LIST_TEAM_REQUEST = `${prefix}/LIST_TEAM_REQUEST`;
export const LIST_TEAM_SUCCESS = `${prefix}/LIST_TEAM_SUCCESS`;
export const LIST_TEAM_FAILURE = `${prefix}/LIST_TEAM_FAILURE`;

export const CREATE_TEAM_REQUEST = `${prefix}/CREATE_TEAM_REQUEST`;
export const CREATE_TEAM_SUCCESS = `${prefix}/CREATE_TEAM_SUCCESS`;
export const CREATE_TEAM_FAILURE = `${prefix}/CREATE_TEAM_FAILURE`;

export const EDIT_TEAM_REQUEST = `${prefix}/EDIT_TEAM_REQUEST`;
export const EDIT_TEAM_SUCCESS = `${prefix}/EDIT_TEAM_SUCCESS`;
export const EDIT_TEAM_FAILURE = `${prefix}/EDIT_TEAM_FAILURE`;

export const CREATE_USER_REQUEST = `${prefix}/CREATE_USER_REQUEST`;
export const CREATE_USER_SUCCESS = `${prefix}/CREATE_USER_SUCCESS`;
export const CREATE_USER_FAILURE = `${prefix}/CREATE_USER_FAILURE`;

export const LIST_USERS_REQUEST = `${prefix}/LIST_USERS_REQUEST`;
export const LIST_USERS_SUCCESS = `${prefix}/LIST_USERS_SUCCESS`;
export const LIST_USERS_FAILURE = `${prefix}/LIST_USERS_FAILURE`;

export const CLEAR_ERROR = `${prefix}/CLEAR_ERROR`;

export const DISABLE_TEAM_REQUEST = `${prefix}/DISABLE_TEAM_REQUEST`;
export const DISABLE_TEAM_SUCCESS = `${prefix}/DISABLE_TEAM_SUCCESS`;
export const DISABLE_TEAM_FAILURE = `${prefix}/DISABLE_TEAM_FAILURE`;

export const ADD_MEMBERS_REQUEST = `${prefix}/ADD_MEMBERS_REQUEST`;
export const ADD_MEMBERS_SUCCESS = `${prefix}/ADD_MEMBERS_SUCCESS`;
export const ADD_MEMBERS_FAILURE = `${prefix}/ADD_MEMBERS_FAILURE`;

export const UPDATE_USER_REQUEST = `${prefix}/UPDATE_USER_REQUEST`;
export const UPDATE_USER_SUCCESS = `${prefix}/UPDATE_USER_SUCCESS`;
export const UPDATE_USER_FAILURE = `${prefix}/UPDATE_USER_FAILURE`;

export const UPDATE_PASSWORD_REQUEST = `${prefix}/UPDATE_PASSWORD_REQUEST`;
export const UPDATE_PASSWORD_SUCCESS = `${prefix}/UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_FAILURE = `${prefix}/UPDATE_PASSWORD_FAILURE`;
export const GET_ORGANIZATIONS_REQUEST = `${prefix}/GET_ORGANIZATIONS_REQUEST`;
export const GET_ORGANIZATIONS_SUCCESS = `${prefix}/GET_ORGANIZATIONS_SUCCESS`;
export const GET_ORGANIZATIONS_FAILURE = `${prefix}/GET_ORGANIZATIONS_FAILURE`;

export const EDIT_USER_REQUEST = `${prefix}/EDIT_USER_REQUEST`;
export const EDIT_USER_SUCCESS = `${prefix}/EDIT_USER_SUCCESS`;
export const EDIT_USER_FAILURE = `${prefix}/EDIT_USER_FAILURE`;

export const CHANGE_USER_PASSWORD_REQUEST = `${prefix}/CHANGE_USER_PASSWORD_REQUEST`;
export const CHANGE_USER_PASSWORD_SUCCESS = `${prefix}/CHANGE_USER_PASSWORD_SUCCESS`;
export const CHANGE_USER_PASSWORD_FAILURE = `${prefix}/CHANGE_USER_PASSWORD_FAILURE`;

export const GET_USERS_REQUEST = `${prefix}/GET_USERS_REQUEST`;
export const GET_USERS_SUCCESS = `${prefix}/GET_USERS_SUCCESS`;
export const GET_USERS_FAILURE = `${prefix}/GET_USERS_FAILURE`;
